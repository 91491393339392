@tailwind base;
@tailwind components;
@tailwind utilities;

html{
    scroll-behavior: smooth;
}

.menu-link {
    @apply          
    inline-block
    relative
    cursor-pointer
    transition-all
    duration-500
    before:content-['']
    before:absolute
    before:-bottom-2
    before:left-0
    before:w-0
    before:h-0.5
    before:rounded-full
    before:opacity-0
    before:transition-all
    before:duration-500
    before:bg-gradient-to-r
    before:from-main-green
    before:to-main-green
    hover:before:w-full
    hover:before:opacity-100
}

#imageSlider {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    animation: slideBg 0s linear infinite 0s;
    animation-timing-function: ease-in-out;
    background-image: url('./assets/cottage4k.jpg');
}

@keyframes slideBg {
    33% {
        background-image: url('./assets/cottage4k.jpg');
    }
    66% {
        background-image: url('./assets/house4k.jpg');
    }
    99% {
        background-image: url('./assets/flat4k.jpg');
    }
    100% {
        background-image: url('./assets/cottage4k.jpg');
    }
}

.bg-header {
    background-color: #2c2b2d;
}